import React from 'react';
import { loadFull } from 'tsparticles';
import Particles from 'react-tsparticles';

const ParticleOptions = {
    autoPlay: true,
    // fullScreen: false,
    backgroundMode: {
        enable: true,
    },
    detectRetina: true,
    fpsLimit: 60,
    infection: {
        cure: false,
        delay: 0,
        enable: false,
        infections: 10,
        stages: []
    },
    manualParticles: [],
    motion: {
        disable: false,
        reduce: {
            factor: 16,
            value: true
        }
    },
    particles: {
        number: {
            value: 160,
            density: {
                enable: true,
                value_area: 800
            }
        },
        color: {
            value: "#ffffff"
        },
        shape: {
            type: "circle",
            stroke: {
                width: 0,
                color: "#000000"
            },
            polygon: {
                nb_sides: 5
            }
        },
        opacity: {
            value: 0.4,
            random: true,
            anim: {
                enable: true,
                speed: 1,
                opacity_min: 0,
                sync: false
            }
        },
        size: {
            value: 3,
            random: true,
            anim: {
                enable: false,
                speed: 4,
                size_min: 0.3,
                sync: false
            }
        },
        line_linked: {
            enable: false,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1
        },
        move: {
            enable: true,
            speed: 1,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 600
            }
        }
    },
    interactivity: {
        detect_on: "canvas",
        events: {
            onhover: {
                enable: true,
                mode: "grab"
            },
            resize: true
        },
        modes: {
            grab: {
                distance: 100,
                line_linked: {
                    opacity: 1
                }
            },
            bubble: {
                distance: 250,
                size: 0,
                duration: 2,
                opacity: 0,
                speed: 3
            },
            repulse: {
                distance: 400,
                duration: 0.4
            },
            push: {
                particles_nb: 4
            },
            remove: {
                particles_nb: 2
            }
        }
    },
    retina_detect: true,
    pauseOnBlur: false,
    pauseOnOutsideViewport: true,
    themes: []
}

const HeroParticles = () => {

    const initParticles = async (main) => await loadFull(main);
    // show the page (hide loader)
    const particlesLoaded = (container) => { }

    return (
        <Particles
            id="tsparticles"
            init={initParticles}
            loaded={particlesLoaded}
            options={ParticleOptions}
        />
    );
}

export default React.memo(HeroParticles);